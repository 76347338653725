import {
  DataGroup,
  GL_COLORS,
  GL_GLStation,
  GLTransferListSideOptions,
  GroupsDevicesStoreState,
  useGroupsDevicesStore,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

import { useDeviceListService } from '../../../Services/deviceListService/useDeviceListService';
import { GetDevicesActivatedLastReadingsResponse } from '../../../Services/deviceListService/useDeviceListService.types';

const IPP_DEVICES = 200;
const IPP_DEVICES_IN_GROUP = 200;

interface GetDevicesInGroupParams {
  devicesFilteredData?: GetDevicesActivatedLastReadingsResponse[];
  text?: string;
  search?: string;
  getFromCache?: boolean;
  nextPageToken?: string;
  devicesFetched?: GetDevicesActivatedLastReadingsResponse[];
}

interface DevicesAvailablesCachedParams {
  devicesFiltereds: GetDevicesActivatedLastReadingsResponse[];
  hasMore: boolean;
  nextPageToken: string | undefined;
}

interface IUseGroupDevicesTransferList {
  t: (key: string) => string;
  availableDevicesSearchIsCorrect: boolean;
  devicesInGroupSearchIsCorrect: boolean;
  devicesAvailables: DataGroup[];
  devicesAddeds: DataGroup[];
  leftSideOptions: GLTransferListSideOptions;
  rightSideOptions: GLTransferListSideOptions;
  groupDevicesState: GroupsDevicesStoreState;
  onTransferItem: (items: DataGroup[], type: 'left' | 'right') => void;
  onSearch: (value: string, side: 'left' | 'right') => void;
}

export const useGroupDevicesTransferList = (): IUseGroupDevicesTransferList => {
  const [devicesAvailables, setDevicesAvailables] = useState<DataGroup[]>([]);
  const [devicesAvailablesData, setDevicesAvailablesData] = useState<
    GetDevicesActivatedLastReadingsResponse[]
  >([]);

  const [devicesAddeds, setDevicesAddeds] = useState<DataGroup[]>([]);
  const [devicesAddedsData, setDevicesAddedsData] = useState<number[]>([]);

  const [availableDevicesSearchIsCorrect, setAvailableDevicesSearchIsCorrect] =
    useState(true);
  const [devicesInGroupSearchIsCorrect, setDevicesInGroupSearchIsCorrect] =
    useState(true);

  const { t } = useI18n();

  const { getDevicesActivatedLastReadings } = useDeviceListService();

  const { state: groupDevicesState, actions: groupDevicesActions } =
    useGroupsDevicesStore();

  const isEditing = false;
  const queryClient = useQueryClient();

  function formatDevicesAvailables(
    currentDevicesAvailables: GetDevicesActivatedLastReadingsResponse[],
  ): DataGroup[] {
    if (!currentDevicesAvailables) {
      return [];
    }

    const devicesFormatted: DataGroup[] = currentDevicesAvailables?.map(
      (device) => {
        return {
          id: String(device.device_id),
          title: device.channels[0].name,
          body: `ID: ${device.device_id}`,
          icon: <GL_GLStation fill={GL_COLORS.FONT_COLOR_VARIANT} />,
        };
      },
    );

    return devicesFormatted;
  }

  async function getMoreDevicesAvailables(): Promise<void> {
    if (
      groupDevicesState.isFetchingMoreAvailableDevices ||
      !groupDevicesState.nextPageTokenAvailableDevices
    ) {
      return;
    }

    groupDevicesActions.setIsFetchingMoreAvailableDevices(true);

    const response = await getDevicesActivatedLastReadings({
      next_page_token: groupDevicesState.nextPageTokenAvailableDevices,
      ipp: IPP_DEVICES,
      group_ids: [],
      activation_reading_failover: true,
      // device_id: searchDeviceIDAvailablesDevices,
    });

    setDevicesAvailablesData([...devicesAvailablesData, ...response.rows]);

    groupDevicesActions.setIsFetchingMoreAvailableDevices(false);
    groupDevicesActions.setNextPageTokenAvailableDevices(
      response.next_page_token,
    );

    const devicesAvailablesCached: DevicesAvailablesCachedParams | undefined =
      queryClient.getQueryData(['get-devices-availables']);

    if (!devicesAvailablesCached) {
      return;
    }

    const { devicesFiltereds: currentDevicesAvailables } =
      devicesAvailablesCached;

    const newDevicesAvailables = response.rows.filter(
      (device) =>
        groupDevicesState.devices_ids.map(Number).indexOf(device.device_id) ===
          -1 && device.groups.length === 0,
    );

    if (currentDevicesAvailables) {
      const queryKeyDevicesInGroup = [
        'get-devices-in-group',
        groupDevicesState.groupsDevicesCardInfo.id,
      ];

      let devicesAddedsCached:
        | GetDevicesActivatedLastReadingsResponse[]
        | undefined = queryClient.getQueryData(queryKeyDevicesInGroup);

      const currentDevicesAvailablesWihoutAddeds =
        currentDevicesAvailables.filter(
          (device) =>
            devicesAddedsCached
              ?.map((item) => item.device_id)
              .indexOf(device.device_id) === -1,
        );

      const newDevicesAvailablesFormatted: DataGroup[] =
        formatDevicesAvailables(
          currentDevicesAvailablesWihoutAddeds.concat(newDevicesAvailables),
        );

      setDevicesAvailables(newDevicesAvailablesFormatted);

      const newCachedDevicesAvailables: DevicesAvailablesCachedParams = {
        devicesFiltereds:
          currentDevicesAvailablesWihoutAddeds.concat(newDevicesAvailables),
        hasMore: response.has_more,
        nextPageToken: response.next_page_token,
      };

      queryClient.setQueryData(
        ['get-devices-availables'],
        newCachedDevicesAvailables,
      );
    }
  }

  const valueTosearchDeviceIDAvailablesDevices = (
    search: string,
  ): string | undefined => {
    return search;
  };

  const leftSideOptions: GLTransferListSideOptions = useMemo(() => {
    return {
      tabs: [
        {
          active: true,
          cacheKey: 'users-availables-transfer-list',
          count: undefined,
          id: 1,
          name: t(
            'groupsDevices.addDevicesModal.transferList.leftSide.tabs.tabOne',
          ),
          onClick: () => {},
        },
      ],
      color: GL_COLORS.ACCENT_COLOR,
      listName: t(
        'groupsDevices.addDevicesModal.transferList.leftSide.tabs.tabOne',
      ),
      onScrollCallback: async () => {
        getMoreDevicesAvailables();
      },
    };
  }, [
    groupDevicesState.devices_ids,
    groupDevicesState.isFetchingMoreAvailableDevices,
    groupDevicesState.nextPageTokenAvailableDevices,
  ]);

  const rightSideOptions: GLTransferListSideOptions = useMemo(() => {
    return {
      tabs: [
        {
          active: true,
          cacheKey: 'users-in-group-transfer-list',
          count: groupDevicesState.isFetchingMoreDevicesInGroup
            ? undefined
            : devicesAddedsData.length,
          id: 1,
          name: t(
            'groupsDevices.addDevicesModal.transferList.rightSide.tabs.tabOne',
          ),
          onClick: () => {},
        },
      ],
      color: GL_COLORS.LIGHT_GREEN,
      listName: t(
        'groupsDevices.addDevicesModal.transferList.rightSide.tabs.tabOne',
      ),
      onScrollCallback: async () => {},
    };
  }, [
    groupDevicesState.isFetchingMoreDevicesInGroup,
    groupDevicesState.nextPageTokenDevicesInGroup,
    devicesAddedsData.length,
  ]);

  async function getDevicesAvailables({
    search,
    nextPageToken = undefined,
    devicesAlreadyFeched,
    getFromCache,
  }: {
    search?: string;
    nextPageToken?: string;
    devicesAlreadyFeched?: GetDevicesActivatedLastReadingsResponse[];
    getFromCache?: boolean;
  }): Promise<{
    devicesFiltereds: GetDevicesActivatedLastReadingsResponse[];
    hasMore: boolean;
    nextPageToken: string | undefined;
  }> {
    const queryKey = ['get-devices-availables'];
    if (search) {
      queryKey.push(search);
    }

    const devicesWithoutSearchCached:
      | DevicesAvailablesCachedParams
      | undefined = queryClient.getQueryData(queryKey);

    if (getFromCache && devicesWithoutSearchCached && !search) {
      const devicesFormatted: DataGroup[] = formatDevicesAvailables(
        devicesWithoutSearchCached.devicesFiltereds,
      );

      setDevicesAvailablesData(devicesWithoutSearchCached.devicesFiltereds);
      setDevicesAvailables(devicesFormatted);

      return {
        devicesFiltereds: [],
        hasMore: devicesWithoutSearchCached.hasMore,
        nextPageToken: devicesWithoutSearchCached.nextPageToken,
      };
    }

    return await queryClient.fetchQuery({
      queryKey,
      queryFn: async () => {
        groupDevicesActions.setIsFetchingMoreAvailableDevices(true);

        const options: {
          ipp: number;
          next_page_token: string | undefined;
          group_ids: number[];
          activation_reading_failover: boolean;
          text?: string;
        } = {
          ipp: IPP_DEVICES,
          next_page_token: nextPageToken,
          group_ids: [],
          activation_reading_failover: true,
        };

        if (search && search.length > 0) {
          options.text = valueTosearchDeviceIDAvailablesDevices(search || '');
        }
        const availableDevices = await getDevicesActivatedLastReadings(options);

        groupDevicesActions.setIsFetchingMoreAvailableDevices(false);

        if (
          !search &&
          availableDevices.has_more &&
          availableDevices.next_page_token
        ) {
          groupDevicesActions.setNextPageTokenAvailableDevices(
            availableDevices.next_page_token,
          );
        }

        const devicesFiltereds = availableDevices.rows.filter(
          (device) =>
            groupDevicesState.devices_ids.indexOf(device.device_id) === -1 &&
            device.groups.length === 0,
        );

        const devicesFormatted: DataGroup[] =
          formatDevicesAvailables(devicesFiltereds);

        setDevicesAvailables(devicesFormatted);

        if (!search) {
          setDevicesAvailablesData(devicesFiltereds);
        }

        return {
          devicesFiltereds: devicesAlreadyFeched
            ? devicesAlreadyFeched.concat(devicesFiltereds)
            : devicesFiltereds,
          hasMore: availableDevices.has_more,
          nextPageToken: availableDevices.next_page_token,
        };
      },
    });
  }

  async function getDevicesInGroup({
    devicesFilteredData,
    devicesFetched,
    text,
    search,
  }: GetDevicesInGroupParams): Promise<void> {
    if (!groupDevicesState.groupsDevicesCardInfo.id) {
      return;
    }

    const queryKey = [
      'get-devices-in-group',
      groupDevicesState.groupsDevicesCardInfo.id,
    ];

    if (search) {
      queryKey.push(search);
    }

    const devicesInGroup = await queryClient.fetchQuery({
      queryKey,
      queryFn: async () => {
        const options: {
          ipp: number;
          next_page_token: string | undefined;
          group_ids: number[];
          activation_reading_failover: boolean;
          text?: string;
          object_readings?: boolean;
          all_devices?: boolean;
        } = {
          ipp: IPP_DEVICES_IN_GROUP,
          next_page_token: undefined,
          object_readings: true,
          all_devices: true,
          group_ids: [Number(groupDevicesState.groupsDevicesCardInfo.id)],
          activation_reading_failover: true,
        };

        if (search && search.length > 0) {
          options.text = valueTosearchDeviceIDAvailablesDevices(search || '');
        }

        const response = await getDevicesActivatedLastReadings(options);

        if (!search && response.has_more && response.next_page_token) {
          groupDevicesActions.setNextPageTokenDevicesInGroup(
            response.next_page_token,
          );
        }

        return devicesFetched
          ? devicesFetched.concat(response.rows)
          : response.rows;
      },
    });

    const devicesFormatted: DataGroup[] = devicesInGroup.map((device) => {
      return {
        id: String(device.device_id),
        title: String(device.channels[0].name),
        body: `ID: ${device.device_id}`,
        icon: <GL_GLStation fill={GL_COLORS.FONT_COLOR_VARIANT} />,
      };
    });

    const devicesAddedsIDs = devicesInGroup.map((device) => device.device_id);

    setDevicesAddeds(devicesFormatted);

    if (devicesFilteredData) {
      const devicesAvailablesFiltered = devicesFilteredData.filter(
        (device) => devicesAddedsIDs.indexOf(device.device_id) === -1,
      );

      setDevicesAvailablesData(devicesAvailablesFiltered);
      setDevicesAvailables(formatDevicesAvailables(devicesAvailablesFiltered));
    }

    if (!search) {
      setDevicesAddedsData(devicesAddedsIDs);

      groupDevicesActions.setGroupsDeviceCardInfo({
        ...groupDevicesState.groupsDevicesCardInfo,
        devices_ids: devicesAddedsIDs,
      });
    }
  }

  async function getMoreDevicesInGroup({
    isFetchingMoreDevicesInGroup,
    nextPageTokenDevicesInGroup,
  }: {
    isFetchingMoreDevicesInGroup?: boolean;
    nextPageTokenDevicesInGroup?: string;
  }): Promise<void> {
    if (isFetchingMoreDevicesInGroup || !nextPageTokenDevicesInGroup) {
      return;
    }

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(true);

    const response = await getDevicesActivatedLastReadings({
      ipp: IPP_DEVICES_IN_GROUP,
      next_page_token: nextPageTokenDevicesInGroup,
      object_readings: true,
      all_devices: true,
      group_ids: [Number(groupDevicesState.groupsDevicesCardInfo.id)],
      activation_reading_failover: true,
    });

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(false);
    groupDevicesActions.setNextPageTokenDevicesInGroup(
      response.next_page_token,
    );

    const devicesInGroupCached:
      | GetDevicesActivatedLastReadingsResponse[]
      | undefined = queryClient.getQueryData([
      'get-devices-in-group',
      groupDevicesState.groupsDevicesCardInfo.id,
    ]);

    if (!devicesInGroupCached) {
      return;
    }

    const newDevicesAvailables = devicesInGroupCached.concat(response.rows);
    const newDevicesIDs = newDevicesAvailables.map(
      (device) => device.device_id,
    );

    const newDevicesAvailablesFormatted: DataGroup[] =
      formatDevicesAvailables(newDevicesAvailables);

    setDevicesAddedsData(newDevicesIDs);
    setDevicesAddeds(newDevicesAvailablesFormatted);

    queryClient.setQueryData(
      ['get-devices-in-group', groupDevicesState.groupsDevicesCardInfo.id],
      newDevicesAvailables,
    );
  }

  async function verifyIfNeedToFetchMoreDevicesAvailables(
    itemsLeft: DataGroup[],
  ) {
    if (
      itemsLeft.length < 7 &&
      groupDevicesState.nextPageTokenAvailableDevices
    ) {
      await getMoreDevicesAvailables();
    }
  }

  async function onTransferItem(
    items: DataGroup[],
    type: 'left' | 'right',
  ): Promise<void> {
    const queryKeyDevicesInGroup = [
      'get-devices-in-group',
      groupDevicesState.groupsDevicesCardInfo.id,
    ];
    const queryKeyDevicesAvailables = ['get-devices-availables'];

    let devicesAddedsCached:
      | GetDevicesActivatedLastReadingsResponse[]
      | undefined = queryClient.getQueryData(queryKeyDevicesInGroup);

    const devicesAvailablesObjectCached:
      | DevicesAvailablesCachedParams
      | undefined = queryClient.getQueryData(queryKeyDevicesAvailables);

    let {
      devicesFiltereds: devicesAvailablesCached,
      hasMore,
      nextPageToken,
    } = devicesAvailablesObjectCached || {};

    if (!devicesAddedsCached || !devicesAvailablesCached) {
      return;
    }

    if (type === 'right') {
      if (!devicesAddedsCached) {
        return;
      }

      const devicesThatWillBeNotRemoved = devicesAddedsCached.filter(
        (device) =>
          items.map((item) => Number(item.id)).indexOf(device.device_id) !== -1,
      );

      const devicesThatWillBeToAddeds = devicesAddedsCached.filter(
        (device) =>
          items.map((item) => Number(item.id)).indexOf(device.device_id) === -1,
      );

      queryClient.setQueryData(
        queryKeyDevicesInGroup,
        devicesThatWillBeNotRemoved,
      );

      if (devicesThatWillBeToAddeds.length > 0 && devicesAvailablesCached) {
        const allDevicesAvailales = [
          ...devicesAvailablesCached,
          ...devicesThatWillBeToAddeds,
        ];

        const allDevicesAvailablesMap = new Map(
          allDevicesAvailales.map((device) => [device.device_id, device]),
        );

        const newCachedDevicesAvailables: DevicesAvailablesCachedParams = {
          devicesFiltereds: Array.from(allDevicesAvailablesMap.values()),
          hasMore: Boolean(hasMore),
          nextPageToken,
        };

        queryClient.setQueryData(
          queryKeyDevicesAvailables,
          newCachedDevicesAvailables,
        );
      }

      const devicesIds = items.map((device) => Number(device.id));

      if (groupDevicesState.devicesInGroupSearch.length === 0) {
        groupDevicesActions.setDevicesIds(devicesIds);

        const devicesAddedsDataFormatted: number[] = items.map((device) =>
          Number(device.id),
        );

        setDevicesAddedsData(devicesAddedsDataFormatted);
      } else {
        const allDevicesID = Array.from(
          new Set(devicesAddedsData.concat(devicesIds)),
        );
        setDevicesAddedsData(allDevicesID);
      }

      setDevicesAddeds(items);
    }

    if (type === 'left') {
      const isToUpdateAvailablesList =
        groupDevicesState.availableDevicesSearch.length === 0 &&
        items.length < 7 &&
        groupDevicesState.nextPageTokenAvailableDevices;

      setDevicesAvailables(items);

      const currentDevicesSorted = items
        .map((item) => {
          return devicesAvailablesCached?.find(
            (device) => device.device_id === Number(item.id),
          );
        })
        .filter((item) => item);

      const devicesThatWillBeToAddeds = devicesAvailablesCached?.filter(
        (device) =>
          currentDevicesSorted
            .map((item) => item?.device_id)
            .indexOf(device.device_id) === -1,
      );

      if (
        devicesThatWillBeToAddeds &&
        devicesThatWillBeToAddeds?.length > 0 &&
        devicesAddedsCached
      ) {
        const allDevicesAddeds = [
          ...devicesAddedsCached,
          ...devicesThatWillBeToAddeds,
        ];

        const allDevicesAddedsMap = new Map(
          allDevicesAddeds.map((device) => [device.device_id, device]),
        );

        queryClient.setQueryData(
          queryKeyDevicesInGroup,
          Array.from(allDevicesAddedsMap.values()),
        );
      }

      let newCachedDevicesAvailables: DevicesAvailablesCachedParams = {
        devicesFiltereds: currentDevicesSorted.map(
          (item) => item as GetDevicesActivatedLastReadingsResponse,
        ),
        hasMore: Boolean(hasMore),
        nextPageToken,
      };

      if (isToUpdateAvailablesList) {
        await verifyIfNeedToFetchMoreDevicesAvailables(items);

        // função acima faz o update do cache, preciso pegar o cache atualizado
        const devicesAvailablesObjectCached:
          | DevicesAvailablesCachedParams
          | undefined = queryClient.getQueryData(queryKeyDevicesAvailables);

        const { devicesFiltereds } = devicesAvailablesObjectCached || {};
        devicesAddedsCached = queryClient.getQueryData(queryKeyDevicesInGroup);

        const devicesThatWillBeToAddeds = devicesFiltereds?.filter(
          (device) =>
            devicesAddedsCached
              ?.map((item) => item?.device_id)
              .indexOf(device.device_id) === -1,
        );

        newCachedDevicesAvailables = {
          ...newCachedDevicesAvailables,
          devicesFiltereds: devicesThatWillBeToAddeds
            ? devicesThatWillBeToAddeds.map(
                (device) => device as GetDevicesActivatedLastReadingsResponse,
              )
            : [],
        };
      }

      queryClient.setQueryData(
        ['get-devices-availables'],
        newCachedDevicesAvailables,
      );

      if (groupDevicesState.devicesInGroupSearch.length > 0) {
        const lastItemFromAvailables = items.slice(-1)[0];

        const newDevicesAddedsData = devicesAddedsData.filter(
          (device) => device !== Number(lastItemFromAvailables.id),
        );

        groupDevicesActions.setDevicesIds(
          newDevicesAddedsData.map((device) => device),
        );

        setDevicesAddedsData(newDevicesAddedsData);
      }
    }
  }

  function onSearch(value: string, side: 'left' | 'right'): void {
    if (side === 'left') {
      groupDevicesActions.setAvailableDevicesSearch(value);
      getDevicesAvailables({
        search: value,
        getFromCache: value.length === 0,
      });
      setAvailableDevicesSearchIsCorrect(true);
    }

    if (side === 'right') {
      groupDevicesActions.setDevicesInGroupSearch(value);

      getDevicesInGroup({
        search: value,
        getFromCache: value.length === 0,
      });
      setDevicesInGroupSearchIsCorrect(true);
    }
  }

  async function initOnOpenModal({
    devicesAlreadyFeched,
    nextPageTokenParam,
  }: {
    nextPageTokenParam?: string;
    devicesAlreadyFeched?: GetDevicesActivatedLastReadingsResponse[];
  }): Promise<void> {
    groupDevicesActions.setIsFetchingMoreDevicesInGroup(true);

    const { devicesFiltereds, hasMore, nextPageToken } =
      await getDevicesAvailables({
        nextPageToken: nextPageTokenParam,
        devicesAlreadyFeched,
      });

    if (devicesFiltereds.length <= 7 && hasMore) {
      initOnOpenModal({
        devicesAlreadyFeched: devicesFiltereds,
        nextPageTokenParam: nextPageToken,
      });
      return;
    }

    setDevicesAddeds([]);

    await getDevicesInGroup({ devicesFilteredData: devicesFiltereds });

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(false);
  }

  useEffect(() => {
    if (groupDevicesState.openAddDevicesModal) {
      initOnOpenModal({});
    }

    if (!groupDevicesState.openAddDevicesModal) {
      setDevicesAddeds([]);
    }

    groupDevicesActions.setAvailableDevicesSearch('');
    groupDevicesActions.setDevicesInGroupSearch('');
  }, [groupDevicesState.openAddDevicesModal, isEditing]);

  useEffect(() => {
    groupDevicesActions.setDevicesIds(devicesAddedsData);
  }, [devicesAddedsData]);

  useEffect(() => {
    getMoreDevicesInGroup({
      isFetchingMoreDevicesInGroup:
        groupDevicesState.isFetchingMoreDevicesInGroup,
      nextPageTokenDevicesInGroup:
        groupDevicesState.nextPageTokenDevicesInGroup,
    });
  }, [
    groupDevicesState.nextPageTokenDevicesInGroup,
    groupDevicesState.isFetchingMoreDevicesInGroup,
  ]);

  return {
    t,
    availableDevicesSearchIsCorrect,
    devicesInGroupSearchIsCorrect,
    devicesAvailables,
    devicesAddeds,
    leftSideOptions,
    rightSideOptions,
    groupDevicesState,
    onTransferItem,
    onSearch,
  };
};
